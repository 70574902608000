import {Livewire} from '../../vendor/livewire/livewire/dist/livewire.esm';
import '../../vendor/wire-elements/pro/resources/js/overlay-component.js';

import Flatpickr from 'flatpickr';
import dayjs from 'dayjs';
import currency from 'currency.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance'

import {livewire_hot_reload} from 'virtual:livewire-hot-reload';
import '@nextapps-be/livewire-sortablejs';

import './alpine';

livewire_hot_reload();

window.dayjs = dayjs;
window.Flatpickr = Flatpickr;
window.currency = currency;
window.bugsnag = Bugsnag;
window.bugsnagPerformance = BugsnagPerformance;

Livewire.start();
